import React, { useState, useEffect } from "react";
import styles from "./Home.module.scss";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";

import LandingCanvas from "../components/LandingCanvas";
import PrintLayer from "../components/PrintLayer";
import Helmet from "react-helmet"



const Home = (props) => {
  const { width, height, layerCount, barHeight, pageData } = props;
  
  const [positions, setPositions] = useState(null);
  const [interacted, setInteracted] = useState(false);
  const c = classNames.bind(styles);

  const images = pageData.images.map((image) => {
      return {
          "color": image.color,
          "image": {
            "_type": "image",
            "asset" : image.image.asset,
            "hotspot" : pageData.images[0].image.hotspot,
            "crop" : pageData.images[0].image.crop
          }
      }
  })

  useEffect(() => {
    if (!positions) {
      let startingPositions = [];
      for (let i = 0; i < layerCount; i++) {
        if (i === layerCount - 1) {
          startingPositions[i] = height - barHeight;
        } else {
          startingPositions[i] = i * barHeight;
        }
      }
      setPositions(startingPositions);
    }
  }, [barHeight, layerCount, height, positions]);

  return (
    <div
      className={styles.container}
      style={{
        width: width,
        height: height,
      }}
    >
      <Helmet>
                <title>Studio Runk</title>
              </Helmet>
      <h1 style={{ position: "absolute", pointerEvents: "none", opacity: 0 }}>
        Studio Ruck
      </h1>
      {positions ? (
        <LandingCanvas
          width={width}
          height={height}
          images={images}
          barHeight={barHeight}
          layerCount={layerCount}
          positions={positions}
          setPositions={setPositions}
          interacted={interacted}
          setInteracted={setInteracted}
        />
      ) : (
        ""
      )}
      {images.map((image, index) => {
        if (!positions) {
          return null;
        }
        return (
          <div
            className={styles.bar}
            key={index}
            style={{
              height: barHeight,
              top: positions[index],
              backgroundColor: image.color,
            }}
          >
            {index === images.length - 2 ? (
              <p
                className={c({ prompt: true }, { hidden: interacted === true })}
              >
                drag down to print the next color
              </p>
            ) : (
              ""
            )}
          </div>
        );
      })}
      <div
        className={styles.navContainer}
        style={{
          height: positions ? positions[positions.length - 2] + "px " : "0px",
        }}
      >
        <div
          className={styles.navInner}
          style={{
            height: height,
          }}
        >
          <div
            className={styles.leftButton}
            style={{
              width: height,
              top: height,
            }}
          >
            <Link to="/about-contact">ABOUT / CONTACT</Link>
          </div>
          <div
            className={styles.rightButton}
            style={{
              width: height,
              left: width - 60,
            }}
          >
            <Link to="/gallery">GALLERY</Link>
          </div>
        </div>
      </div>
      {images.map((image, index) => {
          if (
            !positions
          ) {
            return null;
          }
          return (
            <PrintLayer
              width={width}
              height={height}
              image={image.image}
              color={image.color}
              position={positions[index]}
              key={index}
              index={layerCount - index}
            />
          );
        })}
        <div className={styles.description}>
          <p>{pageData.description}</p>
        </div>
    </div>
  );
};

export default Home;
