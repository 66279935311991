import React from "react";
import styles from "./PrintLayer.module.scss";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import ProgressiveImage from "react-progressive-graceful-image";

const builder = imageUrlBuilder(sanityClient);

const urlFor = (source) => {
  return builder.image(source);
};

const PrintLayer = (props) => {
  const { width, height, position, image, index, color } = props;

  return (
    <div
      className={styles.pull}
      style={{
        width: width,
        height: position,
        zIndex: index,
      }}
    >
      <div
        className={styles.inner}
        style={{
          width: width,
          height: height,
        }}
      >
          <ProgressiveImage
            src={urlFor(image).width(Math.floor(width * 0.6)).height(Math.floor(height - 410)).url()}
            placeholder={urlFor(image).width(Math.floor(width * 0.06)).height(Math.floor((height - 410)*0.1)).url()}
          >
              {(src, loading) => (
                  <img
                    src={src}
                    alt={color}
                    style={{ 
                      width: Math.floor(width * 0.6),
                      height: Math.floor(height - 410),
                      filter: loading ? "blur(10px)" : "blur(0px)" , 
                      backgroundColor: color
                    }}
                  />
                )}
          </ProgressiveImage>

      </div>
    </div>
  );
};

export default PrintLayer;
