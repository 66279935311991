import { useState, useEffect } from "react";

const useMousePosition = () => {
  const [position, setPosition] = useState({
    clientX: 0,
    clientY: 0,
  });

  const updatePosition = e => {

    if(e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel'){
        const evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
        const touch = evt.touches[0] || evt.changedTouches[0];
      const {clientX, clientY } = touch
      
      setPosition({
        clientX, 
      clientY,
      });
      
  } else if (e.type === 'mousedown' || e.type === 'mouseup' || e.type === 'mousemove' || e.type === 'mouseover'|| e.type==='mouseout' || e.type==='mouseenter' || e.type==='mouseleave') {
    const { clientX, clientY } = e;

    setPosition({
      clientX, 
      clientY,
    });
  }
    
  };

  useEffect(() => {
    document.addEventListener("mousemove", updatePosition, false);
    document.addEventListener("mouseenter", updatePosition, false);
    document.addEventListener("touchstart", updatePosition, false);
    document.addEventListener("touchmove", updatePosition, false);

    return () => {
      document.removeEventListener("mousemove", updatePosition);
      document.removeEventListener("mouseenter", updatePosition);
      document.removeEventListener("touchstart", updatePosition, false);
    document.removeEventListener("touchmove", updatePosition, false);
    };
  }, []);

  return position;
};

export default useMousePosition;