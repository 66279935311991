import React from 'react';
import Helmet from "react-helmet"
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

const urlFor = (source) => {
  return builder.image(source);
};

const Metadata = (props) => {

    const { meta } = props;

    return (
        <Helmet>
            
         <meta property="og:title" content={meta.siteTitle ? meta.siteTitle : ''} />
        <meta property="og:url" content={meta.domain ? meta.domain : ''} />
        <meta property="og:image" content={meta.coverImage} />
        <meta property="og:description" content={meta.description ? meta.description : ''} />
        <meta property="business:contact_data:street_address" content="Studio D, First Floor, Print Werks, 1 St James's Mews"/>
<meta property="business:contact_data:locality" content="Brighton"/>
<meta property="business:contact_data:region" content="East Sussex"/>
<meta property="business:contact_data:postal_code" content="BN2 1RT"/>
<meta property="business:contact_data:country_name" content="United Kingdom"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={meta.siteTitle ? meta.siteTitle : ''} />
        <meta name="twitter:site" content={meta.twitter ? "@" + meta.twitter : ''} />
        <meta name="twitter:description" content={meta.description ? meta.description : ''} />
        <meta name="twitter:image" content={urlFor(meta.coverImage).width(1200).height(360).url()} />
        <meta name="twitter:image:alt" content={meta.siteTitle ? meta.siteTitle : ''} />
        <script type="application/ld+json">
          {` "@context": "http://schema.org/",
  "@type": "LocalBusiness",
  "@id": "https://studiorunk.com",
  "name": "Studio Runk",
  "image": "${urlFor(meta.coverImage).width(1200).height(360).url()}",
  "telephone": "07802313072",
  "url": "https://studiorunk.com",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Studio D, First Floor, Print Werks, 1 St James's Mews",
    "addressLocality": "Brighton",
    "addressRegion": "East Sussex",
    "postalCode": "BN2 1RT",
    "addressCountry": "United Kingdom"
  },
  "openingHoursSpecification": [
    {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      "opens": "08:00",
      "closes": "19:00"
    }
  ],
  "sameAs": ["https://www.instagram.com/studiorunk/"]`}
            </script> 
        </Helmet>
    );
};

export default Metadata;