import React from 'react';
import BlockContent from "@sanity/block-content-to-react";
import styles from "./About.module.scss"
import Helmet from "react-helmet"

const About = (props) => {
    const { pageData } = props;
    const { about, intro, email, number, instagram } = pageData;

    return (
        <div className={styles.infoContainer}>
              <Helmet>
                <title>Studio Runk | About | Contact</title>
              </Helmet>
              <div className={styles.section}>
                <BlockContent blocks={about} />
              </div>
              <div className={styles.section}>
                <p>{intro}</p> 
                <a href={"mailto:" + email}>{email}</a> 
                <a href={"tel:" + number}>{number}</a> 
                <a href={instagram}>Instagram</a> 
              </div>
        </div>
    );
};

export default About;