import React, { useRef, useEffect, useState } from "react";
import styles from "./LandingCanvas.module.scss";

let bars = [];
let canvas;
let ctx;
let target;

const LandingCanvas = (props) => {
  const {
    width,
    height,
    images,
    barHeight,
    layerCount,
    positions,
    setPositions,
    interacted,
    setInteracted,
  } = props;
  const canvasRef = useRef();
  const [dragging, setDragging] = useState(false);
  //   const [target, setTarget] = useState(layerCount - 2)

  function bar(id, y, height, color, bottom, upperBound, lowerBound) {
    this.y = y;
    this.bottom = bottom;
    this.height = height;
    this.color = color;
    this.upperBound = upperBound;
    this.lowerBound = lowerBound;
    this.id = id;

    this.checkTarget = function (clientY) {
    //   console.log(clientY + ": " + this.y + ", " + this.bottom);
      if (clientY >= this.y && clientY < this.bottom) {
        target = this.id;
        // console.log(target);
      }
    };

    this.updatePosition = function (clientY) {
    //   console.log(target);
      if (this.id === target) {
            if (this.upperBound === this.y && this.lowerBound === this.bottom) {
            //   this.y = this.y;
            //   this.bottom = this.bottom;
            }
            else if (clientY <= this.upperBound) {
              this.y = this.upperBound;
              this.bottom = this.upperBound + barHeight;
            } else if (clientY >= this.lowerBound) {
              this.y = this.lowerBound - barHeight;
              this.bottom = this.lowerBound;
            } else {
              this.y = clientY - barHeight / 2;
              this.bottom = clientY + barHeight / 2;
            }
      }
      if (this.id === target - 1) {
          this.lowerBound = bars[target].y
      } else if (this.id === target + 1) {
          this.upperBound = bars[target].bottom
      }
      let positionsTemp = []
      for (let i = 0; i < layerCount; i++) {
          positionsTemp[i] = bars[i].y;
      }
      setPositions(positionsTemp);

      
    };

    this.display = function () {
      ctx.fillStyle = this.color;
      ctx.fillRect(0, this.y, width, this.height);
    };
  }

  const draw = (y) => {
    // console.log(y);
    ctx.clearRect(0, 0, width, height);
    for (let i = 0; i < bars.length; i++) {
      bars[i].updatePosition(y);
      bars[i].display();
    }
  };

  const handleMouseDown = (e) => {
    let y;
    if (
      e.type === "mousemove" ||
      e.type === "mousedown" ||
      e.type === "mouseup"
    ) {
      y = e.clientY;
    } else if (
      e.type === "touchmove" ||
      e.type === "touchend" ||
      e.type === "touchstart"
    ) {
      const evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
      const touch = evt.touches[0] || evt.changedTouches[0];
      y = touch.pageY;
    }
    if (!target) {
      target = layerCount - 2;
    }

    if (!interacted && y >= bars[bars.length - 2].y && y <= bars[bars.length - 2].bottom) {
      setInteracted(true);
    }

    // console.log(target);
    for (let i = 0; i < bars.length; i++) {
      bars[i].checkTarget(y);
    }
    setDragging(true);
    // draw(e);
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMousemove = (e) => {
    let y;
    if (
      e.type === "mousemove" ||
      e.type === "mousedown" ||
      e.type === "mouseup"
    ) {
      y = e.clientY;
    } else if (
      e.type === "touchmove" ||
      e.type === "touchend" ||
      e.type === "touchstart"
    ) {
      const evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
      const touch = evt.touches[0] || evt.changedTouches[0];
      y = touch.pageY;
    }

    if (dragging === true && ctx.canvas) {
      draw(y);
    }
  };

  useEffect(() => {
    canvas = canvasRef.current;
    ctx = canvas.getContext("2d");

    canvas.width = width * 2;
    canvas.height = height * 2;
    ctx.scale(2, 2);
    //Create bar objects and set initial positions
    let positionsTemp = [];
    for (let i = 0; i < layerCount; i++) {
      if (i < layerCount - 1) {
        bars[i] = new bar(
          i, //id
          positions[i], //y
          barHeight, //height
          images[i].color, //color
          positions[i] + barHeight, //bottom
          positions[i], //upperBound
          i === layerCount - 2
           ? height - barHeight
           : positions[i] + barHeight //lowerBound
          
        );
        positionsTemp[i] = positions[i];
      } else {
        bars[i] = new bar(
          i, //id
          height - barHeight, //y
          barHeight, //height
          images[i].color, //color
          height, // bottom
          barHeight * (layerCount - 1), //upperBound
          height //lowerBound
        );
        positionsTemp[i] = height - barHeight;
      }
    }
    setPositions(positionsTemp);
    draw(ctx);

    return () => {};
  }, []);

  

  return (
    <canvas
      ref={canvasRef}
      className={styles.main}
      style={{ width: width, height: height }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
      onMouseMove={handleMousemove}
      onTouchMove={handleMousemove}
    />
  );
};

export default LandingCanvas;
