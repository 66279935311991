import React, {Fragment} from "react";
import styles from "./Gallery.module.scss";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import classNames from "classnames/bind";
import ProgressiveImage from "react-progressive-graceful-image";
import Helmet from "react-helmet"

const builder = imageUrlBuilder(sanityClient);

const urlFor = (source) => {
  return builder.image(source);
};

const Gallery = (props) => {
  const { width, height, images } = props;
  const c = classNames.bind(styles);

  return (
    <div className={styles.gallery}>
      <Helmet>
                <title>Studio Runk | Gallery</title>
              </Helmet>
      {images.map((image, index) => (
          <div
            className={c(
              { imageContainer: true },
              { fullWidthContainer: image.layout === "block" },
              { inlineWidthContainer: image.layout === "inline" }
            )}
            key={index}
          >
            {image.layout === "inline" || width < 769 ? (
              <Fragment>
                <ProgressiveImage
                  src={urlFor(image.imageData.image)
                    .width(Math.floor(height * 0.45))
                    .url()}
                    placeholder={urlFor(image.imageData.image)
                        .width(Math.floor(height * 0.045))
                        .url()}
                >
                    {(src, loading) => (
                    <img
                        src={src}
                        alt={image.info}
                        style={{ 
                        width: Math.floor(height * 0.45),
                        height: Math.floor(
                            (height * 0.45) /
                              image.imageData.info.dimensions.aspectRatio),
                        filter: loading ? "blur(10px)" : "blur(0px)"
                        }}
                    />
                    )}
                </ProgressiveImage>
                <div
                  style={{
                    width: Math.floor(height * 0.45),
                    maxWidth: "100%",
                    textAlign: "left",
                  }}
                >
                  <p>{image.info}</p>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <ProgressiveImage
                  src={urlFor(image.imageData.image)
                    .width(Math.floor(width * 0.6))
                    .url()}
                  placeholder={urlFor(image.imageData.image)
                    .width(Math.floor(width * 0.06))
                    .url()}
                  >
                      {(src, loading) => (
                    <img
                        src={src}
                        alt={image.info}
                        style={{ 
                        width: Math.floor(width * 0.6),
                        height: Math.floor(
                            (width * 0.6) /
                              image.imageData.info.dimensions.aspectRatio
                          ),
                        filter: loading ? "blur(10px)" : "blur(0px)"
                        }}
                    />
                    )}
                  </ProgressiveImage>
                <div
                  style={{
                    width: Math.floor(width * 0.6),
                    textAlign: "left",
                  }}
                >
                  <p>{image.info}</p>
                </div>
              </Fragment>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default Gallery;
