import React, { Fragment } from "react";
import { Link } from 'react-router-dom'
import styles from "./UISides.module.scss";

const UISides = (props) => {
  const { width, height } = props;

  return (
    <Fragment>
      <div
        className={styles.leftButton}
        style={{
          width: height,
          top: height,
        }}
      >
        <Link to="/about-contact">ABOUT / CONTACT</Link>
      </div>
      <div
        className={styles.rightButton}
        style={{
          width: height,
          left: width - 60
        }}
      >
        <Link to="/gallery">GALLERY</Link>
      </div>
    </Fragment>
  );
};

export default UISides;
