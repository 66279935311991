import React, { Fragment, useEffect, useState, useCallback } from "react";
import "./styles/globals.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import sanityClient from "./client";

import Logo from "./components/Logo";
import UISides from "./components/UISides";
import Cursor from "./components/Cursor";
import Metadata from "./components/Metadata";

import Home from "./views/Home";
import About from "./views/About";
import Gallery from "./views/Gallery";

function App() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [siteData, setSiteData] = useState(false);

  const fetchSiteData = useCallback(async () => {
    const query = `*[_type == "landing"][0] { 
      "landing" : {
        description,
        images[] {
          "color" : color.hex,
          image
        } 
      },
      "metadata" : *[_type == "metadata"][0] {
        city,
        country,
        coverImage,
        description,
        domain,
        phone,
        postcode,
        region,
        siteTitle,
        streetAdress,
        twitter
      },
      "gallery" : *[_type == "gallery"][0]  {
        "images" : images[] {
          "imageData" : {
            image,
            "info": image.asset->{ "dimensions": metadata.dimensions, "palette" : metadata.palette}
          },
        layout,
        info,
          }
       },
      "about" : *[_type == "aboutContact"][0] {
          about,
          "intro" : contactIntro,
          email,
          instagram,
          number
        }
      }`;

    sanityClient
      .fetch(query)
      .then((data) => {
        setSiteData(data);
      })
      .catch(console.error);
  }, [setSiteData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    if (!siteData) {
      fetchSiteData();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [siteData, fetchSiteData]);

  if (!siteData) {
    return null;
  }


  const layerCount = siteData.landing.images.length;
  const barHeight = 200 / layerCount;

  return (
    <Fragment>
      <BrowserRouter basename="/">
        <Cursor windowDimensions={windowDimensions} />
        <Metadata meta={siteData.metadata} />
        <Logo
          width={windowDimensions.width}
          height={windowDimensions.height}
          logoHeight={barHeight * (layerCount - 1)}
        />

        <Switch key="main-section">
          <Route
            exact
            path="/"
            render={(props) => {
              return (
                <Home
                  {...props}
                  width={windowDimensions.width}
                  height={windowDimensions.height}
                  layerCount={layerCount}
                  barHeight={barHeight}
                  pageData={siteData.landing}
                />
              );
            }}
          />
          <Route
            exact
            path="/about-contact"
            render={(props) => {
              return (
                <Fragment>
                  <UISides
                    width={windowDimensions.width}
                    height={windowDimensions.height}
                  />
                  <About
                    {...props}
                    width={windowDimensions.width}
                    height={windowDimensions.height}
                    pageData={siteData.about}
                  />
                </Fragment>
              );
            }}
          />
          <Route
            exact
            path="/gallery"
            render={(props) => {
              return (
                <Fragment>
                  <UISides
                    width={windowDimensions.width}
                    height={windowDimensions.height}
                  />
                  <Gallery
                    {...props}
                    width={windowDimensions.width}
                    height={windowDimensions.height}
                    images={siteData.gallery.images}
                  />
                </Fragment>
              );
            }}
          />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
