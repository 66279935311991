import React from "react";
import useMousePosition from "../hooks/useMousePosition";
import styles from "./Cursor.module.scss";
import classNames from "classnames/bind";

const Cursor = (props) => {
  const { windowDimensions } = props;

  const c = classNames.bind(styles);
  const { clientX, clientY } = useMousePosition();
  const isTouchDevice =
    "ontouchstart" in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  const black = "#232122";

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        pointerEvents: "none",
      }}
    >
      <div
        className={c(
          { cursor: true },
          {
            hidden:
              clientX <= 1 ||
              clientY <= 1 ||
              isTouchDevice ||
              clientX >= windowDimensions.width - 3 ||
              clientY >= windowDimensions.height - 3,
          }
        )}
        style={{
          left: clientX,
          top: clientY,
        }}
      >
        {/* Outer Circle   */}
        <div
          style={{
            width: "34px",
            height: "34px",
            border: `1px solid ${black}`,
            borderRadius: "99999px",
            left: "8px",
            top: "8px",
          }}
        ></div>
        {/* Top Line Outer   */}
        <div
          style={{
            width: "1px",
            height: "17px",
            backgroundColor: black,
            left: "24.5px",
            top: "0px",
          }}
        ></div>
        {/* Bottom Line Outer   */}
        <div
          style={{
            width: "1px",
            height: "17px",
            backgroundColor: black,
            left: "24.5px",
            bottom: "0px",
          }}
        ></div>
        {/* Left Line Outer   */}
        <div
          style={{
            width: "17px",
            height: "1px",
            backgroundColor: black,
            left: "0px",
            top: "24.5px",
          }}
        ></div>
        {/* Left Line Outer   */}
        <div
          style={{
            width: "17px",
            height: "1px",
            backgroundColor: black,
            right: "0px",
            top: "24.5px",
          }}
        ></div>

        {/* Inner Circle Top Left */}
        <div
          style={{
            width: "7.5px",
            height: "7.5px",
            backgroundColor: black,
            borderRadius: "99999px 0px 0px 0px",
            left: "17px",
            top: "17px",
          }}
        ></div>
        {/* Inner Circle Top Right */}
        <div
          style={{
            width: "7.5px",
            height: "7.5px",
            backgroundColor: black,
            borderRadius: "0px 99999px 0px 0px",
            right: "17px",
            top: "17px",
          }}
        ></div>
        {/* Inner Circle Bottom Left */}
        <div
          style={{
            width: "7.5px",
            height: "7.5px",
            backgroundColor: black,
            borderRadius: " 0px 0px  0px 99999px",
            left: "17px",
            bottom: "17px",
          }}
        ></div>
        {/* Inner Circle Bottom Right */}
        <div
          style={{
            width: "7.5px",
            height: "7.5px",
            backgroundColor: black,
            borderRadius: "0px  0px 99999px 0px ",
            right: "17px",
            bottom: "17px",
          }}
        ></div>
      </div>

    </div>
  );
};

export default Cursor;


